import React from 'react'
import Helmet from 'react-helmet'
import { Box, Lead as Title } from 'rebass'
import Layout from '../components/Layout'
import Login from '../components/Login'

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title>Login</title>
      <meta name="description" content="HYAM Employee Login" />
    </Helmet>

    <Box>
      <Title>Employee Zone</Title>
      <Login />
    </Box>
  </Layout>
)

export default NotFoundPage
