import React from 'react'
import { Box, Flex, Button, Text, Image } from 'rebass'
import { IdentityConsumer } from '../utils/identity.js'

const UserDisplay = ({ name, avatar }) => (
  <Flex>
    <Image src={avatar} />
    <Text>{name}</Text>
  </Flex>
)

const Login = () => (
  <IdentityConsumer>
    {({ user, netlifyIdentity }) => {
      const isUser = !!user
      return (
        <Box mt={4}>
          {isUser && (
            <Box>
              <UserDisplay name={user.full_name} avatar={user.avatar_url} />
              <Button
                onClick={() => {
                  window.netlifyIdentity.logout()
                }}
              >
                Logout
              </Button>
            </Box>
          )}
          {!isUser && (
            <Button
              onClick={() => {
                window.netlifyIdentity.open()
              }}
            >
              Login
            </Button>
          )}
        </Box>
      )
    }}
  </IdentityConsumer>
)

export default Login
